import React from 'react'
import Layout from './Layout'

import Landing from './components/main/Landing'
import ActionButtons from './components/main/ActionButtons'
import Advantages from './components/main/Advantages'
import Toolkit from './components/main/Toolkit'


export default function Main() {
  // TODO: Openings
  return (
    <Layout>
      <Landing />

      <ActionButtons />

      <div className='large-padding' />
      <Advantages />

      <div className='large-padding' />
      <Toolkit />

      <div className='large-padding' />
    </Layout>
  )
}
