import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Footer() {

  const navigate = useNavigate()

  function nav(e, href) {
    e.preventDefault()
    navigate(href)
    return false
  }

  return (
    <footer className='responsive large-elevate top-round' style={{ marginTop: '4rem' }}>
      <div className='grid large-padding large-space'>
        <div className='s12 m4 l4'>
          <h4>Products</h4>
          <p>
            <a onClick={e => nav(e, '/products')} className='link'>
              Our products
            </a>
          </p>
          <p>
            <a target='_blank' rel='noreferrer' onClick={e => nav(e, 'https://iqataxtools.com')} className='link'>
              Tax Tools
            </a>
          </p>
        </div>

        <div className='s12 m4 l4'>
          <h4>Company</h4>
          <p>
            <a onClick={e => nav(e, '/about')} className='link'>
              About IQA
            </a>
          </p>
        </div>

        <div className='s12 m4 l4'>
          <h4>Contact</h4>
          <p>
            <a onClick={e => nav(e, '/contact')} className='link'>
              Contact us
            </a>
          </p>
          <p>
            <a href='mailto:company@iqataxtools.com' className='link'>
              <i>email</i>&nbsp;Email
            </a>
          </p>
          <p>
            <a href='tel:34-000-000-000' className='link'>
              <i>phone</i>&nbsp;Phone
            </a>
          </p>
        </div>
      </div>
      <div className='divider' />
      <p className='right-align'>© 2022 IQA, All Rights Reserved.</p>
    </footer>
  )
}
