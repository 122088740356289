import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ActionButtons() {
  const navigate = useNavigate()

  //TODO
  /*const unused = (
    <>
      <a onClick={e => nav(e, '/jobs')} className='button extra secondary'>
        <i>person</i>
        Careers
      </a>
      <a onClick={e => nav(e, '/contact')} className='button extra'>
        <i>phone</i>
        Contact us
      </a>
    </>
  )*/

  function nav(e, href) {
    e.preventDefault()
    navigate(href)
    return false
  }

  return (
    <nav className='large-padding large-space center-align'>
      <a onClick={e => nav(e, '/about')} className='button extra secondary'>
        <i>add</i>
        Read more
      </a>
    </nav>
  )
}
