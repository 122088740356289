import React from 'react'
import Layout from '../Layout'

export default function About() {
  const approach = [
    ['Concept', 'Analize your requirements'],
    ['Prototype', 'Propose a working solution'],
    ['Production', 'Implementation of the proposal'],
    ['Deployment', 'With continued support'],
  ]
  return (
    <Layout>
      <h1>
        <span className='s m'>About IQA</span>
        <span className='l'>About Intelligence Quality Assurance</span>
      </h1>

      <p>
        We specialize in empowering businesses with
        <span className='bold'> robust and efficient </span>
        testing frameworks, ensuring accelerated development cycles and the delivery of
        <span className='bold'> high-quality </span> software products.
      </p>

      <p>
        A small company based in Cluj-Napoca, 
        we deeply care about providing robustness, safety, and stability 
        to an inherently chaotic industry.
      </p>

      <div className='large-padding'/>
      <h2>Our approach to software</h2>
      <div className='grid large-space'>
        {approach.map((a, i)=>(
          <div className='l3 m6 s6 center-align middle-align' key={i}>
            <article className='small-width round primary'>
              <h1 className='absolute middle center large' style={{opacity: 0.4}}>{i+1}</h1>
              <h4 className='center-align'>{a[0]}</h4>
              <h6 className='center-align'>{a[1]}</h6>
            </article>
          </div>
        ))}
      </div>

      <div className='large-padding'/>
      <h4><i>shield</i> A note on reliability</h4>
      <p>
        Most of the systems we have deployed have not required any maintenance on our part for several years.
      </p>
      <p>
        Our methodology is battle-tested: we are confident in our ability to provide no maintenance software.
      </p>
      <p>
        We are committed to long-term reliability.
      </p>
      

    </Layout>
  )
}
