import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

export default function NavBar() {
  const navigate = useNavigate()
  const location = useLocation()

  const links = [
    ['home', '/', 'Home'],
    ['info', '/about', 'About'],
    ['service_toolbox', '/services', 'Services'],
    ['inventory_2', '/products', 'Products'],
  ]

  // TODO
  /* const unused = [
    ['person', '/jobs', 'Openings'],
    ['contact_support', '/contact', 'Contact'],
  ]*/

  function nav(e, href) {
    e.preventDefault()
    navigate(href)
    return false
  }
  
  const linkList = links.map((l, i) => (
    <a key={i} className={`primary vertical ${location.pathname === l[1] ? 'active' : ''}`} onClick={e => nav(e, l[1])}>
      <i>{l[0]}</i>
      {l[2]}
    </a>
  ))

  return (
    <>
      <nav className='l m left primary medium-elevate' id='desktop-navigation'>
        {linkList}
      </nav>

      <nav className='s bottom primary large-elevate scroll' id='mobile-navigation'>
        {linkList}
      </nav>
    </>
  )
}
