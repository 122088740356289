import React from 'react'
import logo from '../../assets/logo.png'

export default function Landing() {
  return (
    <div className='primary-text' style={{paddingTop: '1rem'}}>
      <img src={logo} width={'70%'} style={{maxWidth: 600}} className='center large-padding' alt='iqa logo'/>
      <p className='center-align'>
        Intelligence Quality Assurance
      </p>
      <h6 className='center-align'>
        <i className='small-margin'>shield_person</i>
        Protect against human error
      </h6>
      <h4 className='center-align'>
        <i className='small-margin'>shield</i>
        Increase reliability
      </h4>
      <h6 className='center-align'>
        <i className='small-margin'>safety_check</i>
        Automate tests for each new version
      </h6>
      <h6 className='center-align'>
        <i className='small-margin'>shield_question</i>
        Get quality reports for each test
      </h6>
    </div>
  )
}
