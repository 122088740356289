import React from 'react'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import DarkModeButton from './components/DarkModeButton'

export default function Layout({children}) {
  return (
    <>
      <DarkModeButton />
      <NavBar />
      <main className='responsive'>
        {children}
      </main>
      <Footer />
    </>
  )
}
