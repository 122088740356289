import React from 'react'
import Layout from '../Layout'
import t from '../assets/taxtools.png'

export default function Products() {
  const taxToolPoints = [
    'Fiscal document analisys',
    'Automated email reports for your clients',
    'Private and Secure: we store no fiscal information',
  ]
  return (
    <Layout>
      <h1>Products</h1>

      <article className='no-padding'>
        <img className='responsive absolute bottom' src={t} alt='corporate digital art' style={{ opacity: 0.2 }} />
        <div className='small-padding'>
          <h2>🗃️ TaxTools</h2>
          <h4>Tax and Books automatization software.</h4>
          <p>A convenient web app to automate your fiscal document handling and resulting billing for clients.</p>

          {taxToolPoints.map(e => (
            <div key={e}>
              <div className='row center-align'>
                <i className='light-green-text'>check_circle</i>
                {e}
              </div>
              <div className='medium-divider' />
            </div>
          ))}

          <div className='center-align'>
            <a target='_blank' rel='noreferrer'href='https://iqataxtools.com' className='button extra'>
              TaxTools<i>arrow_outward</i>
            </a>
          </div>
        </div>
      </article>
    </Layout>
  )
}
