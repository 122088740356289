import React, { useEffect } from 'react'

export default function DarkModeButton() {
  useEffect(()=>{
    if (window.localStorage.getItem('darkmode') === 'true')
      document.body.classList.add('dark')
    else
      document.body.classList.remove('dark')
  })

  function toggleDarkMode() {
    window.localStorage.setItem('darkmode', document.body.classList.toggle('dark'))
  }

  return (
    <button 
      className='absolute top right circle margin'
      onClick={toggleDarkMode}>
      <i>dark_mode</i>
    </button>
  )
}
