import React from 'react'

import a from '../../assets/logos/java.png'
import b from '../../assets/logos/javascript.png'
import c from '../../assets/logos/playwright.svg'
import d from '../../assets/logos/selenium.png'
import e from '../../assets/logos/cucumber.png'
import f from '../../assets/logos/appium.png'
import g from '../../assets/logos/typescript.png'
import h from '../../assets/logos/cypress.svg'

export default function Toolkit() {
  const logos = [a, b, c, d, e, f, g, h]
  return (
    <>
      <h2>Our Tooling Expertise</h2>
      <p>
        Our team of expert technicians cover the entirety of the most commonly used frontend technologies.
      </p>
      <div className='grid' style={{paddingTop: '1rem'}}>
        {logos.map((l, i)=>
          <div key={i} className='s6 m4 l3 center-align'>
            <img src={l} alt='logo' width={128} height={128}/>
          </div>
        )}
      </div>
      
    </>
  )
}
