import React from 'react'
import Layout from '../Layout'
import testing from '../assets/services/testing automation.webp'
import manual from '../assets/services/manual testing.webp'
import qa from '../assets/services/quality assurance.webp'
import managed from '../assets/services/managed testing.webp'
import docs from '../assets/services/test documentation.webp'
import optimize from '../assets/services/qa process optimization.webp'

export default function Services() {
  const services = [
    [
      'Testing automation',
      `
        Reduce costs and avoid human error by implementing
        a comprehensive testing framework that insures your
        product behaves as expected.
      `,
      testing,
    ],
    [
      'Manual Testing',
      `
        Spot opportunities to improve your software,
        and provide a better experience to your clients.
      `,
      manual,
    ],
    [
      'Quality Assurance',
      `
        Need a quick systems check before your deployment?
        Ensure your software meets basic requirements.
      `,
      qa,
    ],
    [
      'Managed Testing',
      `
        Automate versioned testing and spot regressions way
        ahead of deployment.
      `,
      managed,
    ],
    [
      'Test Documentation',
      `
        Receive comprehensive documentation and reports
        for every aspect of testing.
      `,
      docs,
    ],
    [
      'QA process optimization',
      `
        Already deployed a solution?
        We will improve or maintain it.
      `,
      optimize,
    ],
  ]
  return (
    <Layout>
      <h1>Services</h1>

      <div>
        {services.map((s, i) => (
          <div className='grid large-space large-padding' key={i}>
            {i % 2 === 0 && (
              <img
                className='m l m6 l6 circle responsive border small-padding'
                src={s[2]}
                alt='corporate digital art'
              />
            )}
            <img className='s12 s circle responsive border small-padding' src={s[2]} alt='corporate digital art' />
            <div className='s12 m6 l6 middle-align'>
              <div className='center-align'>
                <h2 className='center-align'>{s[0]}</h2>
                <p>{s[1]}</p>
              </div>
            </div>
            {i % 2 !== 0 && (
              <img
                className='m l m6 l6 circle responsive border small-padding'
                src={s[2]}
                alt='corporate digital art'
              />
            )}
          </div>
        ))}
      </div>
    </Layout>
  )
}
