import React, { useEffect, useState } from 'react'
import './advantages-slider.css'

import carousel1 from '../../assets/carousel/a.jpg'
import carousel2 from '../../assets/carousel/b.jpg'
import carousel3 from '../../assets/carousel/c.jpg'
import carousel4 from '../../assets/carousel/d.jpg'
import carousel5 from '../../assets/carousel/e.jpg'
import carousel6 from '../../assets/carousel/f.jpg'
import { isElementInViewport } from '../../utils'

export default function Advantages() {
  const [sliderItem, setSliderItem] = useState(1)

  const articles = [
    [
      carousel1,
      'Archieve Maximum test coverage'
    ],
    [
      carousel2,
      'Speed up your release cycle'
    ],
    [
      carousel3,
      'Detect defects earlier in development'
    ],
    [
      carousel4,
      'No more to finding bugs in production'
    ],
    [
      carousel5,
      'Reduced expense in manual testing'
    ],
    [
      carousel6,
      'Greater product delivery speed'
    ],
  ]

  useEffect(() => {
    const slider = document.getElementById('slider')

    const timeoutID = setTimeout(() => {
      if (!isElementInViewport(slider)) return

      let lastItem = sliderItem
      lastItem += 1
      if (lastItem > articles.length) lastItem = 1

      const currentScroll = document.documentElement.scrollTop
      window.location.hash = `slider-item${lastItem}`
      window.scrollTo({ top: currentScroll, behavior: 'instant' })
      setSliderItem(lastItem)
    }, 3000)

    return () => clearTimeout(timeoutID)
  }, [sliderItem, articles.length])

  return (
    <>
      <h2>The advantages we offer</h2>
      <p>
        Using our mature testing techniques developed over many years of experience offers you an important edge.
      </p>
      
      <nav>
        <div className='max'/>
        <div id='slider' style={{paddingTop: '1rem'}}>
          {articles.map((a, i)=>
            <div key={a[1]} id={`slider-item${i+1}`} className='round center-align top-align no-padding margin'>
              <img src={a[0]} alt='background' className='responsive'/>
              <h6 className='white-text absolute middle bottom no-margin'>{a[1]}</h6>
            </div>
          )}
        </div>
        <div className='max'/>
      </nav>

      <nav id='slider-nav' className='center-align small-space'>
        {articles.map((_, i)=>
          <a key={i} href={`#slider-item${i+1}`} className='chip circle small' onClick={()=>setSliderItem(i+1)}>{i+1}</a>
        )}
      </nav>
    </>
  )
}
