import React from 'react'
import ReactDOM from 'react-dom/client'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import Main from './Main'
import About from './pages/About'
import Services from './pages/Services'
import Products from './pages/Products'

import 'beercss'
import './theme.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/products" element={<Products/>} />
      </Routes>
    </Router>
  </React.StrictMode>
);
